import React from 'react'
import { useModal } from 'react-modal-hook'
import { useFormikContext } from 'formik'
import { useCartContext } from 'context/cart'

import AddressForm from 'containers/cart/address-form'
import { Box } from 'containers/cart/styled'

import Button from 'components/button'
import CheckboxField from 'components/form-field/checkbox'

import { ButtonWrap, StyledExpeditedShippingModal } from './styled'

type FormValues = {
  ship: string
}

type Props = {
  showShippingOptions: boolean
}

const ShippingAddress = ({ showShippingOptions } : Props) => {
  const { applyShip } = useCartContext()
  const {
    values: { ship },
    setFieldValue,
    handleChange,
  } = useFormikContext<FormValues>()
  const [showModal, hideModal] = useModal(() => (
    <StyledExpeditedShippingModal
      hideModal={hideModal}
      isOpen
      ariaHideApp={false}
    >
      <Box>
        <h2>Note on expedited shipping</h2>
        <p>
          By ordering today with expedited shipping, you&apos;ll likely receive
          your kit in 2-3 business days.
        </p>
        <p>
          If you wish to complete the test as soon as it arrives, please note
          that you must abstain from ejaculating for at least 48 hours (but no
          more than 5 days) before doing your test. You also must test in the
          morning, Monday-Thursday and mail it that same day.<span translate="no">&nbsp;Fellow&nbsp;</span>is{' '}
          <strong>not responsible</strong> for shipping refunds if your kit
          arrives to you on a day that you cannot test or are not prepared to
          test.
        </p>
        <ButtonWrap>
          <Button onClick={hideModal}>I understand</Button>
        </ButtonWrap>
      </Box>
    </StyledExpeditedShippingModal>
  ))
  return (
    <Box>
      <h2>Shipping address</h2>
      <AddressForm fieldsPrefix="shipping" />

    {showShippingOptions && (
      <>
        <h3>Shipping options:</h3>
        <CheckboxField
          type="radio"
          name="ship"
          label="UPS Ground Shipping (Free- arrives in 5-7 business days)"
          radioGroupValue={ship}
          value="0"
          onChange={(e: any) => {
            handleChange(e)
            applyShip(parseInt(e.target.value))
            setFieldValue('ship', e.target.value)
          }}
        />
        <CheckboxField
          type="radio"
          name="ship"
          label="UPS Expedited Shipping ($30 - arrives 2-3 business days)"
          radioGroupValue={ship}
          value="3000"
          onChange={(e: any) => {
            showModal()
            handleChange(e)
            applyShip(parseInt(e.target.value))
            setFieldValue('ship', e.target.value)
          }}
        />
      </>
    )}
    </Box>
  )
}
export default ShippingAddress
