/* eslint-disable max-depth */
import React, { FC, useCallback, useState } from 'react'
import { Formik, Form } from 'formik'
import { loadStripe } from '@stripe/stripe-js'
import {
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { navigate } from 'gatsby'
import { stringify, parse } from 'query-string'
import { ModalProvider } from 'react-modal-hook'

import { useCartContext } from 'context/cart'
import { Wrap, Content, Title } from 'containers/cart/styled'
import SEO from 'components/seo'
import ContactInformation from 'containers/cart/contact-information'
import ShippingAddress from 'containers/cart/shipping-address'
import BillingInformation from 'containers/cart/billing-information'
import OrderReview from 'containers/cart/order-review'
import Cart from 'containers/cart/cart/index'
import OrderDetails from 'containers/cart/order-details'
import config from 'common/config'
import api from 'common/api'
import { initialValues, validationSchema } from './form-settings'
import useAllKitProducts from 'hooks/use-all-kit-products'
import useTotalPrice from 'hooks/use-total-price'
import { formatCurrency } from 'utils/format'
import { useSessionContext } from 'context/session'

const stripePromise = loadStripe(config.stripePublicKey)

const processPayment = async (values: PaymentRequestType) => {
  try {
    const payment = await api.processPayment(values)
    if ('status' in payment && payment.status === 'success') return true
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('[error]', e)
    return false
  }
  return false
}

const Checkout: FC = () => {
  const session = useSessionContext()
  const stripe = useStripe()
  const elements = useElements()
  const { items, getCount, clear, promoCode } = useCartContext()
  const { totalOrderPrice, discount } = useTotalPrice()
  const allProducts = useAllKitProducts()
  const [useCustomCheckout, setUseCustomCheckout] = useState(false)

  if (typeof location !== 'undefined') {
    const referralCode = session.referralCode
    initialValues.promoCode = referralCode || ''
  }

  const onCreditCardPay = useCallback(() => {
    setUseCustomCheckout(true)
    if (items && Object.keys(items).length > 0) {
      const eecProducts: any[] = []
      for (const sku in items) {
        if (Object.hasOwnProperty.call(items, sku)) {
          const itemQuantity = items[sku]
          if (itemQuantity > 0) {
            const itemProduct = allProducts.find(
              product => product.id === sku
            )
            if (itemProduct) {
              eecProducts.push({
                id: itemProduct.id,
                name: itemProduct.attributes.name,
                quantity: itemQuantity,
                price: formatCurrency(itemProduct.price),
              })
            }
          }
        }
      }
      dataLayer.push({
        event: 'eec.checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: 'Card' },
            products: eecProducts,
          },
        },
        // @ts-ignore
        branch: window.ab_branch, // branch is defined on the window in our SEO component
      })
    }
  }, [items, allProducts])

  const toggleCustomCheckout = useCallback(() => {
    setUseCustomCheckout(currentState => !currentState)
  }, [setUseCustomCheckout])

  const processCheckout = useCallback(
    async (values: FormValuesType, { setSubmitting, setStatus }) => {
      gtag('event', 'Form Submitted', {
        event_category: 'Checkout',
      })
      setSubmitting(true)
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        gtag('event', 'Form Failure', {
          event_category: 'Checkout',
        })
        return false
      }

      const cardElement = elements.getElement(CardNumberElement)

      if (!cardElement) {
        gtag('event', 'Form Failure', {
          event_category: 'Checkout',
        })
        return false
      }

      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })

        if (error || !paymentMethod) {
          setStatus({
            error: 'payment_method_invalid',
          })
          // eslint-disable-next-line no-console
          console.error(`Payment Method Creation Failed`)
          gtag('event', 'Payment Failed', {
            event_category: 'Checkout',
            event_label: 'payment_method_invalid',
          })
          setSubmitting(false)
          return false
        }
        if (paymentMethod.card?.last4) {
          values.paymentMethodLast4 = paymentMethod.card.last4
        }

        let customerPurchasedSemenAnalysis = false
        const paymentSuccessful = await processPayment({
          items,
          ...values,
          paymentMethodId: paymentMethod.id,
          price: totalOrderPrice,
        })
        if (paymentSuccessful) {
          if (items && Object.keys(items).length > 0) {
            const eecProducts: any[] = []
            for (const sku in items) {
              if (Object.hasOwnProperty.call(items, sku)) {
                const itemQuantity = items[sku]
                if (itemQuantity > 0) {
                  const itemProduct = allProducts.find(
                    product => product.id === sku
                  )
                  if (itemProduct) {
                    eecProducts.push({
                      id: itemProduct.id,
                      name: itemProduct.attributes.name,
                      quantity: itemQuantity,
                      price: formatCurrency(itemProduct.price),
                    })
                    const kitProduct = allProducts[0]
                    if (Boolean(items[kitProduct.id])) {
                      customerPurchasedSemenAnalysis = true
                    }
                  }
                }
              }
            }
            dataLayer.push({
              event: 'eec.purchase',
              ecommerce: {
                currencyCode: 'USD',
                purchase: {
                  actionField: {
                    id: paymentMethod.id,
                    revenue: formatCurrency(totalOrderPrice),
                    shipping: '0',
                    coupon: values.promoCode,
                  },
                  products: eecProducts,
                },
              },
              conversionValue: totalOrderPrice / 100,
              // @ts-ignore
              branch: window.ab_branch, // branch is defined on the window in our SEO component
            })
            clear()
          }

          // Extract custom UTM parameters and forward them to the payment-success page
          // This is being used in tandem with GTM/Optimize to serve variable content
          // to users for our Alpha launch based on their starting URL
          let qString = ''
          const parsedQParams = parse(location.search)
          const utmParams = Object.keys(parsedQParams)
            .filter(key => key.toString().startsWith('utm'))
            .reduce((obj, key) => {
              return {
                ...obj,
                [key]: parsedQParams[key],
              }
            }, {})
          qString += stringify(utmParams)
          if (customerPurchasedSemenAnalysis) {
            qString += `&purchased-sa=true`
          }
          await navigate(`/purchase-success/?${qString}`)
        } else {
          setStatus({
            error: 'pay_endpoint_failed',
          })
          gtag('event', 'Payment Failed', {
            event_category: 'Checkout',
            event_label: 'pay_endpoint_failed',
          })
          // eslint-disable-next-line no-console
          console.error('Payment Failed')
        }
        setSubmitting(false)

        return true
      } catch (e) {
        setStatus({
          error: 'payment_method_invalid',
        })
        // eslint-disable-next-line no-console
        console.error(`Payment Method Creation Failed`, e)
        gtag('event', 'Payment Failed', {
          event_category: 'Checkout',
          event_label: 'payment_method_invalid',
        })
        setSubmitting(false)

        return false
      }
    },
    [stripe, elements, items, totalOrderPrice, clear, allProducts]
  )

  const validate = (values: FormValuesType) => {
    const errors: FormFieldLevelErrors = {}
    if (discount !== 0 && promoCode?.is_referral && !values.clinic_sharing_agreement) {
      errors.clinic_sharing_agreement = "Required"
    }
    return errors
  }

  return (
    <Wrap>
      <SEO title="Cart" />
      <ModalProvider>
        <Content>
          <Title>Checkout</Title>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={processCheckout}
            validate={validate}
          >
            {({ handleSubmit, handleBlur, values, errors }) => (
              <Form id="checkout">
                <Cart
                  onCreditCardPay={onCreditCardPay}
                  collapsed={useCustomCheckout}
                  onClickTitle={toggleCustomCheckout}
                />
                {useCustomCheckout && (
                  <>
                    <ContactInformation />
                    <ShippingAddress
                      showShippingOptions={config.showShippingOptionsInCart}
                    />
                    <BillingInformation />
                    <OrderReview />
                  </>
                )}
              </Form>
            )}
          </Formik>
          {Boolean(getCount()) && (
            <OrderDetails />
          )}
        </Content>
      </ModalProvider>
    </Wrap>
  )
}

const WithElements: FC = props => {
  return (
    <Elements stripe={stripePromise}>
      <Checkout {...props} />
    </Elements>
  )
}

export default WithElements
